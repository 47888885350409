import axios from 'axios'
import Cookies from 'js-cookie';
import config from './config';
const loginData = (!Cookies.get('loginSuccessPropertyOwner')) ? [] : JSON.parse(Cookies.get('loginSuccessPropertyOwner'));

export const request = (path, data, method, serverPath) => {
    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        headers: {
            'Content-Type': 'application/json'
        },
        dataType: 'json'
    };
    if (loginData) {
        options.headers['Authorization'] = loginData?.authToken
    }
    if (method === 'GET') {
        options['params'] = data
    } else {
        options['data'] = data
    }
    let res = axios(options)
    res.then(res1 => { })
    return res
}

export const requestFormData = (path, data, method, serverPath) => {
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var options = {
        method: method,
        url: `${serverPath}/${path}`,
        data: form_data,
        headers: { authorization: loginData?.authToken },
    };
    let res = axios(options);
    res.then(res1 => { })
    return res
}

export const postRequest = async (path, data) => await request(path, data, 'POST', `${config.serverPath}authadmin`)
export const getRequest = async (path, data) => await request(path, data, 'GET', `${config.serverPath}authadmin`)
export const postRequestFormData = async (path, data) => await requestFormData(path, data, 'POST', `${config.serverPath}authadmin`)

export const userPostRequest = async (path, data) => await request(path, data, 'POST', `${config.serverPath}auth`)
export const userGetRequest = async (path, data) => await request(path, data, 'GET', `${config.serverPath}auth`)

export const OwnerPostRequest = async (path, data) => await request(path, data, 'POST', `${config.serverPath}propertyOwner`)
export const OwnerPutRequest = async (path, data) => await request(path, data, 'PUT', `${config.serverPath}propertyOwner`)
export const OwnerGetRequest = async (path, data) => await request(path, data, 'GET', `${config.serverPath}propertyOwner`)
export const propertyOwnerRequestForm = async (path, data) => await requestFormData(path, data, 'POST', `${config.serverPath}propertyOwner`)

export const vehiclePostRequest = async (path, data) => await request(path, data, 'POST', `${config.serverPath}vehicleOwner`)
export const vehicleGetRequest = async (path, data) => await request(path, data, 'GET', `${config.serverPath}vehicleOwner`)
export const vehicleRequestForm = async (path, data) => await requestFormData(path, data, 'POST', `${config.serverPath}vehicleOwner`)