const config = {
  baseUrl: "/",
  //  serverPath: "http://localhost:8000/api/",

  // Live Server
  // serverPath: "https://propertynu.com/api/authadmin",
  // imageUrl: "https://propertynu.com/api/propertyOwner/uploads/",

  serverPath: "https://dev.propertynu.com/api/",
  imageUrl: "https://dev.propertynu.com/media/",

  // DO NOT CHANGE //
  REACT_APP_FB_APP_ID: "1364246204459038",
  GOOGLE_CLIENT_ID: "316641084538-7u4rrb19nu62ecgqafasrflm8n4jk59q.apps.googleusercontent.com",
  CLIENT_ID: "ATmRXQlro43qGwjkNCidcv5s-4eLKDos5INqh5D-AL3WdaGphHKylDVaxAD-bDmU53D76iEXcaLtSUcu",
  APP_SECRET: "EB8__1pwppIQZ3orL0_6mhVyKM1brDv8GBDbnQbvuGyhtlWybl8BOExKivZPIVacdOHZlwBQLbd_3e8t",
  stripePrivateKey: "",
  googleMapsApiKey: "AIzaSyATxU4112etWoagzrKUILzSLFBH37H5C1k",
  fygaroJWTEncryptionKey : "yr6mkwqbk412at9"

};
export default config;
