import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import config from "../coreFIles/config";
import {
    useStripe,
    useElements,
    PaymentElement,
} from "@stripe/react-stripe-js";
import toast, { Toaster } from "react-hot-toast";
import { insertBookingDetailsAction } from "../Action/user.action"

const StripeForm = (data) => {
    const navigate = useNavigate();

    const [processing, setProcessing] = useState(false);
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setProcessing(true);
        if (!stripe || !elements) {
            setProcessing(false);
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            confirmParams: {
                return_url: ``,
            },
        });

        if (result.error) {
            setProcessing(false);
            toast.error(result.error.message);
        } else {
            console.log(result);
            console.log('data',data)
            if (result.paymentIntent.status === "succeeded") {
                let arr = {
                    'propertyId': parseInt(data.data.id),
                    'payerId': "",
                    'orderId': result.paymentIntent.id,
                    'paymentMethodId': 2,
                    "receiptUrl":"",
                    'numberOfAdults': parseInt(data.data.numberOfAdults),
                    'numberOfChildren': parseInt(data.data.numberOfChildren),
                    'noOfInfants' : data.data.numberOfInfants,
                    'noOfPets' : data.data.numberOfPets,
                    'checkInDate': data.data.checkInDate,
                    'checkOutDate': data.data.checkOutDate,
                    'roomType' : data.data.roomType,
                    "cardNumber": "",
                    "expiryDate": "",
                    "accountNumber": "",
                    "amount": data.data.amount,
                    "ifscCode": "",
                    "upiId": "",
                    "propertyPurchaseTypeId" : data.data.propertyPurchaseTypeId,
                    "paymentResponse" : result,
                }
console.log(arr, "instrip from ")
                let resultAPI = await insertBookingDetailsAction(arr)
                
                if (resultAPI.success) {
                    toast.success(resultAPI.msg)
                    setTimeout(() => {
                        navigate(`${config.baseUrl}userdashboard/propertyBooking`);
                    }, 2000);
                } else {
                    toast.error(resultAPI.msg)
                }
            } else {
                setProcessing(false);
                toast.error("Payment failed. Go back to application and try again!");
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Toaster></Toaster>
            <PaymentElement />

            {processing ? (
                <button
                    style={{
                        padding: "6px",
                        background: "#00EAAE",
                        color: "#1D2B39",
                        marginTop: "18px",
                        borderRadius: "5px",
                    }}
                    disabled={true}
                >
                    Please wait
                </button>
            ) : (
                ""
            )}

            {!processing ? (
                <button
                    className='w-100 rounded-1 mt-3 btn btn-primary'
                    style={{
                        padding: "6px",
                        marginTop: "18px",
                        borderRadius: "5px",
                    }}
                    disabled={!stripe}
                >
                    Pay Now ( ${data.data.amount} ){" "}
                </button>
            ) : (
                ""
            )}
        </form>
    );
};

export default StripeForm;
