import React, { useState, useEffect } from "react";
import Header from "./directives/header";
import Footer from "./directives/footer";
import "./generalCSS/home.css";
import "./generalCSS/aboutus.css";
import "./generalCSS/generalnavbar.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  getAboutUsAction,
  getWhatWeOfferAction,
  getPromisesDetailsAction,
  getTeamAction,
} from "../Action/user.action";
import {
  Container,
  Row,
  Col,
  Card,
  ButtonToolbar,
  Button,
} from "react-bootstrap";

import configCore from "../coreFIles/config";
const AbountUs2 = () => {
  const [aboutUs, setAboutUs] = useState([]);
  const [desc, setDesc] = useState("");
  const [promiseDetails, setpromiseDetails] = useState([]);
  const [whatWeOffer, setWhatWeOffer] = useState([]);
  const [team, setTeam] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
   
    getAboutUsAPI();
    getPromisesDetails();
    getWhatWeOffer();
    getTeamDetails();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  const getAboutUsAPI = async () => {
    let res = await getAboutUsAction();
    if (res.success) {
      setAboutUs(res.data);
    } else {
    }
  };
  const getTeamDetails = async () => {
    let res = await getTeamAction();
    if (res.success) {
      setTeam(res.data);
    }
  };
  const getWhatWeOffer = async () => {
    let res = await getWhatWeOfferAction();

    if (res.success) {
      setWhatWeOffer(res.data);
    }
  };
  const getPromisesDetails = async () => {
    let res = await getPromisesDetailsAction();
    if (res.success) {
      setpromiseDetails(res.data);
    }
  };

  return (
    <>
      <Header />
      {loading ? (
        <div className="loader-container">
     
         <img src="assets/images/loader-home.gif" style={{height:'100px', width:'100px'}}/>
        </div>
      ) : (
        <div>
          <section className=" banner-area-two bg-light bannerHome">
            <Container>
              <Row className="align-items-center">
                <Col xl={6} lg={6} md={6} className="mb-4">
                  <div className="">
                    <h1 className="text-primary">
                      <b>About Us</b>
                    </h1>

                    {aboutUs ? (
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: aboutUs[0]?.shortDesc,
                          }}
                        />
                      </>
                    ) : (
                      "No data found"
                    )}
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} className="text-center">
                  <div
                    className="about-us-radius"
                    style={{ textAlign: "center" }}
                  >
                    <img src={aboutUs[0]?.image} className="about-us-radius" style={{ objectFit: "cover" }}/>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="ourvision" style={{ background: "#fbf9ec" }}>
            <Container>
              <Row className="d-flex align-items-center justify-content-center">
                <Col lg={6} md={6} className="mb-3 d-flex align-items-center">
                  <div className="vision-image text-center">
                    <img src={aboutUs[1]?.image}  style={{ objectFit: "cover" }}/>
                  </div>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  className="d-flex justify-content-center align-items-center "
                >
                  <div className="vision-content bg-white d-flex justify-content-center flex-column">
                    <h2 className="text-primary mb-4">
                      <b>Our Vision</b>
                    </h2>
                    {/*   <p className="fw-bold">*/}
                    {aboutUs ? (
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: aboutUs[1]?.shortDesc,
                          }}
                        />
                      </>
                    ) : (
                      "No data found"
                    )}
                    {/* <a
                  className="rounded-5 btn btn-primary px-4 blogsection"
                  variant="primary"
                  href={`${config.baseurl}ourVision`}
                >
                  Learn More
                </a> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="ourmission position-relative">
            <Container>
              <Row className="justify-content-center align-items-center missionflex">
                <Col lg={6} md={6} className="omission">
                  <div className="ourmission-content d-flex justify-content-center flex-column">
                    <h2 className="text-primary mb-4">
                      <b>Our Mission</b>
                    </h2>

                    {aboutUs ? (
                      <>
                        <p
                          className="about-para text-left"
                          dangerouslySetInnerHTML={{
                            __html: aboutUs[2]?.shortDesc,
                          }}
                        />
                      </>
                    ) : (
                      "No data found"
                    )}
                  </div>
                </Col>
                <Col lg={6} md={6} className="">
                  <div className="d-sm-flex align-items-center">
                    <div className="ourmission-image text-center ">
                      <img src={aboutUs[2]?.image}  style={{ objectFit: "cover" }}/>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-0 our_promise">
            <Container fluid className="px-0">
              <div className="text-center bg_heading py-5">
                <Container>
                  <Row>
                    <Col lg={12} className="">
                      <h1 className=" mb-4">
                        <b>What We Offer</b>
                      </h1>
                      <div class="section-title-line"> </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
            <Container fluid className="px-0">
              <div
                className="py-5"
                style={{
                  background: "linear-gradient(45deg, #f4d0c4, #f3efd6)",
                }}
              >
                <Container>
                  <Row>
                    {whatWeOffer.length > 0 &&
                      whatWeOffer.map((item, i) => (
                        <Col lg={4} className="text-center  bg-transparent">
                          <Card className="p-5 pt-0 rounded-5 border-0">
                            <div className="mb-4">
                              <img
                                src={
                                  item?.image
                                    ? item.image
                                    : ""
                                }
                                style={{ objectFit: "cover" }}
                              />
                            </div>

                            <h4 className="">
                              <b>{item.title}</b>
                            </h4>
                            <>
                              <p className="mb-3">{item.shortDesc}</p>
                            </>
                          </Card>
                        </Col>
                      ))}
                  </Row>
                </Container>
              </div>
            </Container>
          </section>
          <section
            className="pt-0 our_promise"
            style={{ background: "#fbf9ec" }}
          >
            <Container className="pt-5">
              <Row>
                <Col lg={12} className="mb-5">
                  <div className="text-center mb-4">
                    <h1 className="">
                      <b>Our Promise To Our Users</b>
                    </h1>
                    <div class="section-title-line"> </div>
                  </div>
                </Col>
              </Row>
              <Row>
                {promiseDetails.length > 0 &&
                  promiseDetails.map((item, i) => (
                    <Col lg={4} className="text-center">
                      <Card className="p-4 rounded-5 border-0">
                        <div className="mb-4">
                          <img src="assets/images/standard-quality.jpg" />
                        </div>

                        <h4 className="mb-4">
                          <b> {item.title}</b>
                        </h4>
                        <>
                          <p
                            className="our-content"
                            dangerouslySetInnerHTML={{
                              __html: item?.description,
                            }}
                          />
                         
                        </>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </Container>
          </section>
          <section className="managing-director">
            <Container>
              <Row className="align-items-center">
                <Col lg={6} className="directorImage">
                  <div className="rounded">
                    <img
                      src={
                        team?.image
                          ? configCore.imageUrl + team.image
                          : ""
                      }
                      className="rounded" style={{ objectFit: "cover" }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="directorName">
                    <h2 className="">
                      <b>{team.name}</b>
                    </h2>
                    <h5>{team.designation}</h5>
                  </div>
                </Col>
              </Row>
              <Row></Row>
            </Container>
          </section>
        </div>
      )}
      <Footer />
    </>
  );
};

export default AbountUs2;
